<div class="col-12 p-0" style="height: 90vh;display: flex">
    <div class="col-md-6 col-sm-12 col-12 p-0">
        <div class="d-flex">
            <div class="login-wrap">
                <div class="login-html">
                    <div class="mob-logo">
                        <div class="logo-mob-text" style="color: #782E8D">T f W</div>
                    </div>
                    <div class="login-center py-5 px-4">
                        <input id="tab-1" type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">Sign
                            In</label>
                        <input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab">Sign
                            Up</label>
                        <div class="login-form">
                            <div class="sign-in-htm">
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Placeholder" [(ngModel)]="email">
                                </mat-form-field>
                                </div>
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput placeholder="Placeholder"  type="password" data-type="password" [(ngModel)]="password">
                                </mat-form-field>
                                </div>
                                <div class="group py-3">
                                    <input id="check" type="checkbox" class="check" checked>
                                    <label for="check"><span class="icon"></span> Keep me Signed in</label>
                                </div>
                                <div class="group">
                                    <input type="submit" class="button" value="Sign In" (click)="loginUser();">
                                </div>
                                <div class="hr"></div>
                                <div class="foot-lnk">
                                    <a (click)="resetPassword()">Forgot Password?</a>
                                </div>
                            </div>
                            <div class="sign-up-htm" [formGroup]="register">
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Enter valid email address</mat-label>
                                    <input matInput placeholder="Placeholder"  type="text" formControlName="email" [(ngModel)]="email">
                                </mat-form-field>
                                </div>
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Create Password</mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="password" type="password" data-type="password" [(ngModel)]="password">
                                </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Confirm Password</mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="conpassword" type="password" data-type="password" [(ngModel)]="conpassword" >
                                </mat-form-field>
                                <div class="error-message" *ngIf="register.errors != null && register.errors.passwordNotMatch  && register.get('conpassword').touched">
                                    Password mismatch
                                </div>
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Restaurant Name</mat-label>
                                    <input matInput placeholder="Placeholder"  type="text" formControlName="hotel_name" [(ngModel)]="hotel_name">
                                </mat-form-field>
                                </div>
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Address</mat-label>
                                    <input matInput placeholder="Placeholder"  type="text" formControlName="address" [(ngModel)]="address">
                                </mat-form-field>
                                </div>
                                <div class="group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Mobile Number</mat-label>
                                    <input matInput placeholder="Placeholder"  type="text" formControlName="mobile_number" [(ngModel)]="mobile_number">
                                </mat-form-field>
                                </div>
                                <div class="hr" style="margin: 0px 0px 30px"></div>
                                <div class="group pb-2">
                                    <input type="submit" class="button" value="Sign Up" (click)="registerUser()">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <div style="width: 50vw;">
                <img src="../../assets/background.jpeg" />
            </div> -->
        </div>
    </div>
    <div class="col-md-6 col-sm-12 col-12 p-0 bgimage">
        <div style="    margin-top: 35vh;text-align: center;">
            <div>
                <img src="../../assets/login-tfw.png" [width]="100" style="position: absolute;right: 2vw;bottom: 0vh;" />
                <p style="line-height:2px;text-align:center;">
                    <span class="logo-text" style="font-size : xxx-large;color:#e7ccee;">
                        T   F   W <br><span class="logo-text">
                            TouchFreeWaiter</span>
                    </span>
                </p>

            </div>
        </div>
    </div>
</div>