<div class="p-1" *ngIf="!data.camon">
    <div class="d-flex ">
        <button mat-raised-button class="col" (click)="cameraOption(); openLink($event)" style="border-radius: 15px;">
            <i class="material-icons blue-text font-26 " style="color: #782E8D;">add_a_photo</i>
            <span style="color: #782E8D; font-weight: bold;" class="p-2 font-15">Camera</span>
        </button>
    </div>
    <div class="mt-2 d-flex">
        <input style="display: none" type="file" multiple (change)="onFileSelect($event)" #hiddenfileinput accept="image/x-png,image/gif,image/jpeg" >
        <button mat-raised-button class="col" style="border-radius: 15px;" (click)="hiddenfileinput.click()">
            <i class="material-icons blue-text font-26 " style="color: #782E8D;">collections</i>
            <span style="color: #782E8D; font-weight: bold;" class="p-2 font-15">Choose from device</span>
        </button>
    </div>
</div>
<div *ngIf="data.camon && !imagetaken">
    <webcam [allowCameraSwitch]="true" (imageCapture)="handleImage($event)" [trigger]="triggerObservable" [videoOptions]="{width: {min: 1280, ideal: 1920}, height: {min: 720, ideal: 1080}}"></webcam>
    <div class="col-12 takebutton">
        <button mat-raised-button class="btn takenbuttonprop" (click)="triggerSnapshot()">
        </button>
    </div>
</div>
<div *ngIf="imagetaken && data.camon">
    <img [src]="webcamImage.imageAsDataUrl || selectedImg" class="takenimgsize" />
    <div class="col d-flex" style="bottom:10px;position:absolute;">
        <div class="col">
            <i class="material-icons-outlined red-text font-40 float-left mx-n3" (click)="cancleImg()">delete</i>
        </div>
        <div class="col">
            <i class="material-icons-outlined green-text font-40 float-right pr-3" (click)="selectedImgsuccess()">thumb_up_alt</i>
        </div>
    </div>
</div>