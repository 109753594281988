import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { CookieService } from 'ngx-cookie-service';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-qrcodegen',
  templateUrl: './qrcodegen.component.html',
  styleUrls: ['./qrcodegen.component.css']
})
export class QrcodegenComponent implements OnInit {

  username: string;
  loadsuccess: boolean = false;
  link: string = 'UserLink';
  main: string = "";
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  constructor(public cookies: CookieService, private service: AppServiceService, public appmod: AppComponent, public router: Router) {
    this.username = this.cookies.get('LoginUser');
    // this.main = location.origin;
    this.main = "https://www.touchfreewaiter.com/"
    console.log(this.main)
    if (this.username) {
      appmod.selectedTab = 2;
      this.getLink();
    }
    else
      this.router.navigate(['/', 'login']);
  }

  ngOnInit(): void {
    this.loadsuccess = false;
  }
  downloadImage() {
    window.scrollTo(0, 0);
    if (this.loadsuccess)
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.canvas.nativeElement.style.display = 'none';
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/jpg');
        this.downloadLink.nativeElement.download = 'QrCode.jpg';
        this.downloadLink.nativeElement.click();
      });
  }
  getLink() {
    var body = {
      "username": this.username
    }
    this.service.postCall(body, '/hotellink').subscribe((response) => {
      if (response['statusCode'] == 200) {
        setTimeout(() => {
          this.loadsuccess = true;
          this.link = response['link']
          this.link = this.main + this.link;
        }, 50);
      }
    })
  }

}
