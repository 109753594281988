import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { LoginComponent } from './login/login.component';
import { AddmenuComponent } from './addmenu/addmenu.component';
import { AddmenuoptionComponent } from './addmenu/addmenu.component';
import { WebcamModule } from 'ngx-webcam'
import { HttpClientModule } from '@angular/common/http';
import { CustomerComponent } from './customer/customer.component';
import { QrcodegenComponent } from './qrcodegen/qrcodegen.component';
import { QRCodeModule } from 'angularx-qrcode';
import { HomeComponent } from './home/home.component';
import { MenusComponent, DeletemenuComponent } from './menus/menus.component';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NgxLoadingXConfig, NgxLoadingXModule, POSITION, SPINNER } from 'ngx-loading-x';
import { AboutComponent } from './about/about.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdminviewComponent } from './adminview/adminview.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ForgetPassComponent } from './forget-pass/forget-pass.component';
const ngxLoadingXConfig: NgxLoadingXConfig = {
  show: true,
  bgBlur: 4,
  bgOpacity: 8,
  // bgLogoUrl: '../assets/background.jpeg',
  // bgLogoUrlPosition: POSITION.bottomRight,
  // bgLogoUrlSize: 75,
  spinnerType: SPINNER.circleSpinner,
  spinnerSize: 120,
  spinnerColor: '#dd0031',
  spinnerPosition: POSITION.centerCenter,
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AddmenuComponent,
    AddmenuoptionComponent,
    CustomerComponent,
    QrcodegenComponent,
    HomeComponent,
    MenusComponent,
    DeletemenuComponent,
    AboutComponent,
    AdminviewComponent,
    ForgetPassComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    WebcamModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    QRCodeModule,
    LightboxModule,
    GalleryModule,
    DragDropModule,
    MatCheckboxModule,
    NgxLoadingXModule.forRoot(ngxLoadingXConfig)

  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  entryComponents: [AddmenuComponent, MenusComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
