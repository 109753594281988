<div class="qr-container">
    <div class="col-lg-6 col-xl-6 col-md-6 shadow  d-lg-inline-block" style="background-color: white;top:10vh;max-height:600px">
        <div #screen class="p-3" style="overflow: hidden auto;height: calc(100vh - 100px);display: inline;">
            <h5>
                <div class="d-flex">
                    <div class="col-12 qr-font text-center d-flex justify-content-center" style="line-height: 26px;">
                        <span style="width: 215px;">MENU</span>
                        <div class="d-flex" style="margin-left: -3.75rem;">

                            <span class="material-icons" style="font-size: 28px;">
                            photo_camera
                        </span>
                            <span style="margin-left: 0.75rem;"><img src="../../assets/google-lens-icon_1.png" [width]="23" [height]="23" /></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!loadsuccess" class="qrgif-pos">
                    <img src="../../assets/qrgif.gif" [width]="220" [height]="220" />
                </div>
                <qrcode *ngIf="loadsuccess" [qrdata]="link" [width]="275" [errorCorrectionLevel]=" 'M' " [elementType]="'url'"></qrcode>
                <div class="qr-font pb-3">www.touchfreewaiter.com</div>
            </h5>
        </div>
        <div class="btn" style="margin: 15px;float: right;margin-top: -0px;font-family: 'Poppins';" mat-raised-button [class]="{'button_left-right' : loadsuccess , 'mat-select-panel' : !loadsuccess}" (click)="downloadImage() ">
            <span *ngIf="!loadsuccess">Searching</span>
            <span *ngIf="loadsuccess">Download</span>
        </div>
        <div id="download ">
            <img #canvas>
            <a #downloadLink></a>
        </div>
    </div>

</div>