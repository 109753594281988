<div *ngIf="!data.imgloaded" class="mat-card-subtitle ">
    <h4 style="color: #782E8D; font-weight: bold;">Welcome, {{ data.username }}</h4>
    <div class="col" style="margin: 0px 20px ; color: #782E8D; font-weight: bold;">Upload your restaurant food menu</div>
    <div class="text-right ">
        <button mat-raised-button class="btn  " style="background-color: #782E8D; color: #ffff;" (click)="openAddMenuPopup(false) ">
            Add Images  
        </button>
    </div>
</div>
<div *ngIf="data.imgloaded " class="pb-2 px-2 card ">
    <div class="col">
        <h2 mat-dialog-title  style="color: #782E8D;" >Selected menu</h2>
    </div>
    <div class="row p-2">
        <div class="box-list-horizontal" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
            <div class="py-2 p-2" *ngFor="let image of data.imgData;let i=index" cdkDrag>
                <div class="example-custom-placeholder thumbimage" *cdkDragPlaceholder></div>
                <img [src]="image" class="thumbimage" />
                <span class="material-icons" style="position: absolute;margin-left: -20px;color: red;font-size: large;" (click)="closeImage(i)">
                cancel
            </span>
            </div>
        </div>
        <div class="box-list-horizontal" *ngIf="data.imgData.length < 5" (click)="openAddMenuPopup(true)">
            <div class="py-2 p-2">
                <div class="example-custom-placeholder thumbimage p-lg-5 p-sm-4">
                    <span class="material-icons" style="color: #782E8D;">add</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-2 box-list-vertical" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
        <div class="m-2 py-2 p-2 d-flex mat-raised-button" *ngFor="let image of data.imgData;let i=index" cdkDrag>
            <div class="col">
                <div class=" blue-text"> Menu - {{i + 1}}</div>
            </div>
            <div class="col">
                <img [src]="image" class="thumbimage" />
                <span class="material-icons" style="position: absolute;margin-left: -20px;color: red;font-size: large;" (click)="closeImage(i)">
                    cancel
                </span>
            </div>
        </div>
    </div>
    <div class="mb-2 py-2 p-2 mat-raised-button box-list-vertical" *ngIf="data.imgData.length < 5" style="margin-top: -8px;" (click)="openAddMenuPopup(true)">
        <div class="d-flex">
            <div class="col">
                <div class=" blue-text" style="color: #782E8D; font-weight: bold; "> Add</div>
            </div>
            <div class="col" style="text-align: -webkit-center;">
                <div class="example-custom-placeholder thumbimage p-2">
                    <span class="material-icons" style=" color: #782E8D;">add</span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex ">
        <div class="col text-center">
            <button mat-raised-button class="btn w-upload " style="color: #ffff;" [class]="{ 'button__left-right' : data.imgloaded}" (click)="uploadMenuPopup()">
                Upload
            </button>
        </div>
    </div>
</div>
<ngx-loading-x [show]="load"></ngx-loading-x>