import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppServiceService } from './app-service.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'TouchFree Waiter';
  load: boolean = false;
  usermode: boolean = false;
  homepage: boolean = false;
  loginstatus: boolean;
  selectedTab: Number;
  navlist = [{ 'href': '/#/home', 'title': 'Home' ,'icon': 'home'},
  { 'href': '/#/menu-dashboard', 'title': 'Menu','icon': 'restaurant_menu' },
  { 'href': '/#/qrcode', 'title': 'QR Code','icon': 'qr_code'  },
  // { 'href': '/#/about', 'title': 'About' },
  { 'href': '/#/login', 'title': 'Login', 'icon': 'login' },
  { 'href': '/#/', 'title': 'Logout' , 'icon': 'logout' }
]

  constructor(public cookie: CookieService, public service: AppServiceService) {
    this.loginstatus = this.service.loginstatus;
  }
  ngOnInit() {
    // if (this.loginstatus) {
    //   this.navlist.push({ 'href': '/#/', 'title': 'Logout' , 'icon': 'logout'});
    // }
  }
  navHeader(index) {
    if(index == 0){
      this.homepage = true;
    }
    else{
      this.homepage = false;
    }
    if (index == 4) {
      this.logout();
      this.selectedTab = 0;
      this.homepage = true;
    }
    else
      if (!this.loginstatus && index != 0 && index != 3) {
        localStorage.setItem("currenttab", '4')
        this.selectedTab = parseInt(localStorage.getItem('currenttab'))
      }
  }
  logout() {
    this.loginstatus = this.service.loginstatus = false;
    this.cookie.delete('LoginUser')
  }
  ngOnChanges() {

  }
  getbackground()
  {
    const queryString = window.location.hash;
    
    const page_type = queryString.split(/[\?/=]+/)
    if (page_type[1] == 'home' || page_type[0] == '' ) {
      this.homepage = true;
      return true;
    }
    return false;
  }

}
