import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from '@ngx-gallery/core';
import { Lightbox } from "@ngx-gallery/lightbox";
import { CookieService } from 'ngx-cookie-service';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component'
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  styles: ['i.g-btn-close{display : none}']
})
export class CustomerComponent implements OnInit {

  data: any = [];
  userlink: string;
  items: GalleryItem[];
  constructor(public gallery: Gallery, public cookies: CookieService, public router: Router,
    private service: AppServiceService, private route: ActivatedRoute,
    private lightbox: Lightbox, private appmod : AppComponent) { 
    this.appmod.usermode = true;
    this.appmod.load = true;
    }

  ngOnInit(): void {
    this.route.url.subscribe(url => this.userlink = url[0].path);
    this.getUserMenu();
  }
  getUserMenu() {
    var body = {
      "userlink" : this.userlink
    }
    this.service.postCall(body, '/usermenu').subscribe((response) => {
      if (response['statusCode'] == 200) {
        for(var i=0;i<response['images'].length; i++){
          this.data[response['images'][i]['order']] = response['images'][i]
        }
        this.items = this.data.map(item =>
          new ImageItem({ src: item['image'], thumb: item['image'] })
        );
        this.appmod.load = false;
        this.withCustomGalleryConfig();
        
      }
    }, (error) => {
      setTimeout(() => {
        this.router.navigate(['/', 'menu-dashboard']);
        this.appmod.load = false;
      }, 500);
      console.log('Error', error)
    })
  }
  withCustomGalleryConfig() {

    const lightboxGalleryRef = this.gallery.ref('lightbox');
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Contain,
      thumb: false,
      dots: false,
      counter: false,
      loop: false,
      loadingStrategy: 'preload',
      thumbMode: 'free',
    });
    lightboxGalleryRef.load(this.items);
    this.lightbox.open();
    var menus = document.getElementsByClassName('g-btn-close')
    menus[0].classList.remove("g-btn-close")
  }

}
