import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

declare let Swiper: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public appmod: AppComponent) { 
    appmod.selectedTab = 0;
  }
  redirectToWebsite() {
    window.open('https://www.tfwinc.in/', '_blank');
  }
  
  ngOnInit(): void {
    console.log(document.getElementsByClassName('testi-user-img '))
    var galleryThumbs = new Swiper('.gallery-thumbs', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: '2',
      // coverflowEffect: {
      //   rotate: 50,
      //   stretch: 0,
      //   depth: 100,
      //   modifier: 1,
      //   slideShadows : true,
      // },
      
      coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 50,
            modifier: 6,
            slideShadows : false,
        },
        
      });
      
      
    var galleryTop = new Swiper('.swiper-container.testimonial', {
      speed: 400,
      spaceBetween: 50,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      direction: 'vertical',
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      },
      thumbs: {
        swiper: galleryThumbs
        }
      });
      
  }
  
}
