import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AddmenuComponent } from '../addmenu/addmenu.component'
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';
export interface UserData {
  username: string;
  password: string;
  imgloaded: boolean;
  imgData: [];
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string = '';
  password: string = '';
  conpassword: string = '';
  email: string = '';
  hotel_name: string = '';
  mobile_number: string = '';
  page_type : any;
  address: string = '';
  register: FormGroup;
  constructor(public router: Router, public dialog: MatDialog, private service: AppServiceService,
    public cookieService: CookieService, public Appmod: AppComponent, public fb: FormBuilder) {

    const queryString = window.location.hash;

    this.page_type = queryString.split(/[\?/=]+/)
    if (this.page_type[1] == 'verify') {
      this.validateAccount(this.page_type[3])
    }
    this.register = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      conpassword: ['' && this.password == this.conpassword, Validators.required],
      address: [''],
      hotel_name: ['', Validators.required],
      mobile_number: ['']
    }, { validators: this.passwordcheck.bind(this) })
  }

  ngOnInit(): void {
  }
  loginUser() {
    this.Appmod.load = true;
    var body = {
      "email": this.email,
      "password": this.password
    }
    let url = '/login'
    this.service.postCall(body, url).subscribe((response) => {
      if (response['statusCode'] == 200) {
        localStorage.setItem("currenttab", '1')
        // setTimeout(() => {
          this.username = response['username']
          this.Appmod.load = false;
          this.service.loginstatus = true;
          this.Appmod.loginstatus = true;
          // if (this.username == 'Touchfreeadmin')
          //   this.router.navigate(['/', 'adminView']);
          // else
          this.router.navigate(['/', 'menu-dashboard']);
        // }, 500);
        if (!this.cookieService.get('LoginUser')) {
          this.cookieService.set('LoginUser', this.username)
        }
      }
      else if (response['statusCode'] == 202) {
        window.alert(response['message'])
        this.Appmod.load = false;
      }
    }, (error) => {
      console.log('Error', error)
    })
  }
  registerUser() {
    if (!this.password.length || !this.conpassword.length ||
      !this.hotel_name.length || !this.email.length || this.conpassword != this.password ) {
      window.alert("Please fill correct details")
    }
    else {
      this.Appmod.load = true;
      var body = {
        "email": this.email,
        "password": this.password,
        "cpassword": this.conpassword,
        "restaurant_name": this.hotel_name,
        "address": this.address,
        "mobile_number": this.mobile_number
      }

      let url = '/register'
      this.service.postCall(body, url).subscribe((response) => {
        console.log(response, 'Response')
        if (response['statusCode'] == 200) {
          this.service.loginstatus = true;
          this.verifyEmail();
          // window.alert("sucess")
        }
        else {
          this.Appmod.load = false;
          window.alert(response['statusMessage'])
        }
      }, (error) => {
        console.log('Error', error)
      })
    }
  }
  resetPassword() {
    if (this.email == '') {
      window.alert("Please enter email")
    }
    else {
      this.Appmod.load = true;
      var body = {
        "email": this.email,
        "status": "resetpass"
      }
      this.service.postCall(body, '/sendmail').subscribe((response) => {
        if (response['statusCode'] == 200) {  
          this.Appmod.load = false;
          window.alert("check your registered mail")
        }
        else {
          window.alert("Invalid username")
        }
      })
    }
  }
  verifyEmail() {
    var body = {
      "email": this.email,
      "status": "validate",
      'url' : this.page_type[2]
    }
    this.service.postCall(body, '/sendmail').subscribe((response) => {
      if (response['statusCode'] == 200) {
        this.Appmod.load = false;
        window.alert("Please check your registered email")
      }
    })
  }
  validateAccount(code) {
    console.log(code);
    var body = {
      "code": code,
      "verify" : 'validate'
    }
    this.service.postCall(body, '/verification').subscribe((response) => {
      if (response['statusCode'] == 200) {
        window.alert("Welcome")
      }
    })
  }
  passwordcheck(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: conPassword } = formGroup.get('conpassword');
    return password === conPassword ? null : { passwordNotMatch: true };
  }

}
