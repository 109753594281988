<div style="align-items: center;">
    <div class="col-md-5 col-lg-5 col-10 forget-box pt-4 pb-5">
        <h4>Password Reset</h4>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>email</mat-label>
                <input matInput placeholder="Placeholder" [(ngModel)]="email" [disabled]="true">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput placeholder="Placeholder" type="password" data-type="password" [(ngModel)]="pass">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Confirm password</mat-label>
                <input matInput placeholder="Placeholder" type="password" data-type="password" [(ngModel)]="cpass">
            </mat-form-field>
        </div>
        <div class="float-right">
            <button mat-raised-button color="primary" (click)="resetPassword()">Reset</button>
        </div>
    </div>
</div>