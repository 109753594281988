<section>
    <div class="main">
        <div class="submenu">
            <a id="gifimage" href="https://www.tfwinc.in/" target="_blank">
                <img class="gifimage" src="../../assets/home6.gif" alt="sample1">
              </a> 
              <div class="main head" style="flex: 1;">
        <h1 class="subhead"> We’ve shifted our <br>website to a new site! </h1>
        <div>
             <a id="gifimage2" href="https://www.tfwinc.in/" target="_blank">
        <img class="gifimage2" src="../../assets/button.gif" alt="sample2">
      </a> 
        </div>
        <div id="view">
            <button class="viewbtn" (click)="redirectToWebsite()">view site</button>
        </div>
    </div>
  
    </div>
    <!-- <a id="gifimage2" href="https://www.tfwinc.in/" target="_blank">
        <img class="gifimage2" src="../../assets/home4.gif" alt="sample2">
      </a>  -->
        <!-- <div class="test">
            
        <a class="home">
            <img id="img1"  href="https://www.tfwinc.in/" target="_blank" src="../../assets/home.jpg" alt="sample">
        </a>

        
        </div> -->
    </div>

    

</section>




<section class="row" style="background: white; font-family: Poppins; font-weight: bold;    padding: 8px 0px">
    <div class="col text-center">
        <span>Copyrights© 2024 All Rights Reserved</span>
    </div>
</section>














<!-- <section class="main">
    <div id="img1">
        <img href="https://www.tfwinc.in/" target="_blank" src="../../assets/home.jpg" alt="sample"> 
    </div>
    <div id="head">
        <h1>We have moved our website </h1>  
        <button class="viewbtn"  (click)="redirectToWebsite()">view site</button>
        <div>
            <a href="https://www.tfwinc.in/" target="_blank">
                <img class="gifimage" src="../../assets/see our product.gif" alt="sample1">
              </a>        
            </div>
        
    </div>
  
</section> -->













<!-- <section class="bg">
    <div id="img1">
        <img class="bac" src="../../assets/tfw-home.png" alt="">
    </div>
    <div  class="websiteurl" >
        <p>www.tfwinc.in/</p>
    </div>
    <div id="text">
        <div class="ph-head">
            <h1 class="head">We have moved our website </h1> 
        </div> -->

<!-- <div class="ph-para">
        <p class="para" >We are pleased to inform you that effective, the website address will be changed. Click to view new site</p>
       </div> -->
<!-- <div>
        <a href="https://www.tfwinc.in/" class="btn" target="_blank">View Site</a>
    </div>
    
        <div class="ph-desktop">
            <img src="../../assets/home.jpg" alt="" class="desktop"> 
        </div>
        
    </div> -->
<!-- <div class="content">
        <img src="../../assets/desktop.jpg" alt="" class="desktop"> 
    </div> -->
<!-- <div class="text-content">
        <div class="para">
            <p class="para">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos pariatur facilis esse reiciendis deleniti sed error non! Asperiores, laborum necessitatibus!</p>
        </div>
        <div id="btn">
            <button class="btn">view site</button>
        </div>
    </div> -->
<!-- </section> -->







<!-- <section class="row">
   
    <div class="slide1-bg">
        
        <div id="main">
            <div id="box1"></div>
            <div id="box2">
                <div id="text">
                    <span id="sub-text">
                        WE HAVE LAUNCHED OUR NEW WEBSITE
                    </span>

                </div>
                <button id="btn">
                    view site
                </button>
            </div>

        </div>
    </div>

</section> -->
<!-- <section>
    <div >
    <img src="../../assets/tfw.png" class="aos-item mobile " alt="">
    </div>
</section> -->
<!-- <section class="row" style="background: white;">
    <div class="col-12 section2-head">Scan the code to see the menu</div>
    <div style="margin: 0 auto;">
        <img src="../../assets/Phone menu .png" class="aos-item mobile" />
    </div>
    <div style="width: 60%;;margin: auto 50px;" class="aos-item pt-5 text-nowrap" data-aos="slide-left"
        data-aos-duration="10000">
        <div class="section2-content">
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color ">check</i> <span>
                    No app to download.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    No hardware to install.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Great for food and beverage menus.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    No physical contact in restaurants.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Optimized for all devices.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Contactless is here for the long haul.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Re-ordering.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Less wait-staff assistance.
                </span>
            </div>
            <div class="aos-item" data-aos="slide-left" data-aos-duration="1000">
                <i class="material-icons font-26 tick-color">check</i> <span>
                    Unlimited menus and unlimited scans.
                </span>
            </div>
        </div>
    </div>
</section>
<section class="row">
    <div class="col-12 section2-head" style="color: white !important;">User comments</div>

    <div class="testimonial-section">
        <div class="testi-user-img">
            <div class="swiper-container gallery-thumbs">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img class="u1" src="../../assets/testimonial1.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u1" src="../../assets/whitespace.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u2" src="../../assets/whitespace.jpg" alt="">
                    </div>

                    <div class="swiper-slide">
                        <img class="u4" src="../../assets/whitespace.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u5" src="../../assets/testimonial5.jpg" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="u6" src="../../assets/whitespace.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="user-saying">
            <div class="swiper-container testimonial"> -->
<!-- Additional required wrapper -->
<!-- <div class="swiper-wrapper "> -->
<!-- Slides -->
<!-- <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">
                            <p>
                                “I am glad my dine in customers can easily access our menu through mobile phone with QR
                                code from touchfreewaiter.com..“
                            </p>
                            <div class="name">-Junaid Ahmed-</div>
                            <div class="designation">Butterfly Effect Cafe - Chennai</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “I can now change my items in the menu and modify my food prices on a daily basis..“
                            </p> -->
<!-- <div class="name">-XXX-</div> -->
<!-- <div class="designation">Restaurant Owner</div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “I always look for ways to avoid touching the physical menu in restaurants, especially
                                in these COVID times...“
                            </p>
                            <div class="name">-Baranidharan Nithiyananthan-</div>
                            <div class="designation">Thiruvanmiyur - Chennai</div>

                        </div>
                    </div> -->
<!-- <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “Scanning QR code and accessing the menu through my mobile phone helps me reduce contact
                                points in the restaurants and keep my kids safe...“
                            </p>
                            <div class="name">-Hima Penumuru-</div>
                            <div class="designation">Malleshwaram - Bengaluru</div>

                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “No more guesswork if the menu card is sanitized before I touch it...“
                            </p>
                            <div class="name">-Devi Nadimpally -</div>
                            <div class="designation">Kothapet, Hyderabad</div> -->

<!-- </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="quote">
                            <img class="quote-icon" src="../../assets/quote.png" alt="">

                            <p>
                                “I hate to raise my hand and ask the waiter for a menu card. With QR code in front of
                                me, I can lookup the menu whenever I want..“
                            </p>
                            <div class="name">-Devaraj Narayan-</div>
                            <div class="designation">Ottapalam, Palakkad</div>

                        </div>
                    </div>

                </div>
                <div class="swiper-pagination swiper-pagination-white" style="right: 40px !important;"></div>

            </div>
        </div>
    </div> 
</section> -->
<!-- <div id="imagePrincipale">
    <h1 class="aos-item" data-aos="zoom-in" data-aos-delay="500" data-aos-duration="2000" data-aos-once="false">
        Touchfree Waiter</h1>
    <div id="premierTrait" class="aos-item" data-aos="flip-left" data-aos-duration="3000"></div>
    <h3 class="aos-item" data-aos="zoom-out-up" data-aos-delay="1500" data-aos-duration="3000" data-aos-once="false">
        bon appétit
    </h3>
</div>  -->
