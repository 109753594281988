import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserData } from '../login/login.component';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { AppServiceService } from '../app-service.service';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-addmenu',
  templateUrl: './addmenu.component.html',
  styleUrls: ['./addmenu.component.css']
})
export class AddmenuComponent implements OnInit {
  load: boolean = false;
  constructor(public router: Router, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddmenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserData, private _bottomSheet: MatBottomSheet,
    private service: AppServiceService) { }

  ngOnInit(): void {

  }
  openAddMenuPopup(alreadyAdded) {
    var currentlist = [];
    if (alreadyAdded) {
      currentlist = this.data.imgData
    }
    const config: MatBottomSheetConfig = {
      panelClass: 'addmenu-optionslist',
      data: {
        "camon": false,
        "username": this.data.username,
        "imgcache": currentlist
      }
    }
    this._bottomSheet.open(AddmenuoptionComponent, config)
  }
  uploadMenuPopup() {
    this.load = true;
    var body = {
      "imageData": this.data.imgData,
      "username": this.data.username
    }
    this.service.postCall(body, '/uploadmenu').subscribe((response) => {
      if (response['statusCode'] == 200) {

        setTimeout(() => {
          this.load = false;
          location.reload();
          this.dialog.closeAll();
        }, 100);
        location.reload();
      }
    }, (error) => {
      console.log('Error', error)
    })
  }
  closeImage(i) {
    if (this.data.imgData.length - 1 == 0)
      this.data.imgloaded = false;
    this.data.imgData.splice(i, 1)
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.imgData, event.previousIndex, event.currentIndex);
  }
}

@Component({
  selector: 'app-addmenuoption',
  templateUrl: './addmenuoption.component.html',
  styleUrls: ['./addmenu.component.css']
})
export class AddmenuoptionComponent implements OnInit {
  @Output()
  public pictureTaken = new EventEmitter<WebcamImage>();
  imagetaken: boolean = false;
  public multipleWebcamsAvailable = false;
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;
  private selectedFile = [];
  selectedImg: any;
  constructor(private _bottomSheetRef: MatBottomSheetRef<AddmenuoptionComponent>, private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public dialog: MatDialog) {
    this.selectedImg = data['imgcache'];
  }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
  cameraOption() {

    // this._bottomSheetRef.dismiss();
    const config: MatBottomSheetConfig = {
      panelClass: 'addmenu-cameraoption',
      data: {
        "camon": true,
        "username": this.data.username,
        "imgcache": this.selectedImg
      }
    }
    this._bottomSheet.open(AddmenuoptionComponent, config)
  }
  openLink(event: MouseEvent): void {
    // this._bottomSheetRef.dismiss();
    // event.preventDefault();
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.imagetaken = true;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }
  readFile(index) {
    var reader = new FileReader();
    var file = this.selectedFile[index]
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.selectedImg.push(reader.result)
    };
  }
  onFileSelect(event) {
    this.selectedFile = event.target.files;
    console.log(this.selectedFile)
    if ((this.selectedFile.length + this.selectedImg.length) <= 5) {
      for (var i = 0; i < this.selectedFile.length; i++) {
        this.readFile(i)
        if (this.selectedFile[i]['type'] == "application/pdf") {
          console.log(this.selectedImg)
        }
        else {
          if (i == this.selectedFile.length - 1) {
            this._bottomSheetRef.dismiss();
            this.openDialogAfterLoaded()
          }
        }
      }
    }
  }

  cancleImg() {
    this._bottomSheet.dismiss()
    event.preventDefault();
    const config: MatBottomSheetConfig = {
      panelClass: 'addmenu-cameraoption',
      data: {
        "camon": true
      }
    }
    this._bottomSheet.open(AddmenuoptionComponent, config)
    this.imagetaken = false;

  }
  selectedImgsuccess() {
    this.selectedImg.push(this.webcamImage.imageAsDataUrl)
    this._bottomSheetRef.dismiss();
    this.openDialogAfterLoaded();
  }
  openDialogAfterLoaded() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(AddmenuComponent, {
      position: {
        top: '5vh',
      },
      panelClass: 'box-responsive',
      backdropClass: 'backdropBackground',
      data: { imgloaded: true, imgData: this.selectedImg, username: this.data.username }
    });
  }
}
