import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';

@Component({
  selector: 'app-adminview',
  templateUrl: './adminview.component.html',
  styleUrls: ['./adminview.component.css']
})
export class AdminviewComponent implements OnInit {
  hotelList:any = [];
  constructor( public service: AppServiceService) { }

  ngOnInit(): void {
    this.getHotels()
  }
getHotels(){
  this.service.gethotel().subscribe((response) => {
    if(response['statusCode'] == 200){
      console.log(response)
      this.hotelList = response['hotelList']
    }
  })
}

}
