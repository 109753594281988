import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-forget-pass',
  templateUrl: './forget-pass.component.html',
  styleUrls: ['./forget-pass.component.css']
})
export class ForgetPassComponent implements OnInit {

  email = "";
  pass = "";
  cpass = "";
  constructor(private service: AppServiceService, public appmod: AppComponent,
    public router: Router) {
    const queryString = window.location.hash;
    
    const page_type = queryString.split(/[\?/=]+/)
    if (page_type[1] == 'forget-password') {
      appmod.load = true;
      this.validateCode(page_type[3])
    }

   }

  ngOnInit(): void {
  }
  validateCode(code){
    this.service.postCall({"code" : code, "verify" : 'forgetpass'}, '/verification').subscribe((response) =>{
      if(response['statusCode'] == 200){
        console.log(response);
        this.appmod.load = false;
        this.email = response['email']
      }
      else{
        window.alert(response['message'])
        this.appmod.load = false;
        this.router.navigate(['/', 'login']);
      }
    })
  }
  resetPassword(){
    console.log(this.cpass,this.pass)
    if(this.pass.length !=0 && this.cpass.length !=0){
      if(this.pass == this.cpass){
        this.appmod.load = true;
        var body = {
          "email" : this.email,
          "password" : this.pass
        }
        this.service.postCall(body, '/resetpass').subscribe((response) =>{
          if(response['statusCode'] == 200){
            console.log(response);
            this.appmod.load = false;
            this.router.navigate(['/', 'login']);
          }
        })
      }
      else{
        window.alert("password mismatch")
      }
    }
  }
}
