<div style="height: 100vh; display:flex; justify-content: center;">
    <div class="card about-contain p-3">
        <div style="font-weight: bold;">
            Support
        </div>
        <div style="padding: 18px">
            support@touchfreewaiter.com
        </div>
        <div style="font-weight: bold;">
            Jobs
        </div>
        <div style="padding: 18px">
            jobs@touchfreewaiter.com
        </div>
    </div>
</div>