import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators'
import { environment } from '../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class AppServiceService {
  loginstatus: boolean;
  baseUrl: string = environment.apiUrl
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  private subject = new Subject<any>();
  constructor(private http: HttpClient, public cookieService: CookieService) {
    this.loginstatus = cookieService.get('LoginUser') ? true : false;
  }



  getCall(url: string) {
    return this.http.get<any>(this.baseUrl + url, this.httpOptions).pipe(
      tap(
        (data) => {
          console.log(data)
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }

  postCall(body: any, url: string) {
    return this.http.post<any>(this.baseUrl + url, body, this.httpOptions).pipe(
      tap(
        (data) => {
          console.log(data)
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }


  verifymail(body) {
    return this.http.post('/api/sendmail', body)
  }
  gethotel() {
    return this.http.get('/api/gethotels')
  }
  activateacc(body) {
    return this.http.post('/api/activate', body)
  }
}
